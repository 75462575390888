html,
body {
    background-color: #f2f2f2;
    line-height: 1;
    overflow-x: hidden;
}
#dashboard-react-app,
.dashboard-container {
    height: 100%;
}
#dashboard-react-app {
    overflow: hidden auto;
}
* {
    hyphens: auto;
    overflow-wrap: break-word;
    outline: none;
    word-break: break-word;
    word-wrap: break-word;
}
.ant-select-dropdown > .ant-select-item-option {
    color: #391636;
}
